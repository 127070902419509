//import { UserTypes } from 'store/auth/types'
import { KnownDowntimePeriod /*, SystemDownTypes*/ } from 'types/downtime.ts'
//import { createDowntimePeriod } from 'utils/downtime'

export const KNOWN_DOWNTIME_PERIODS: KnownDowntimePeriod[] = [
  // Time is in UTC
  /*createDowntimePeriod(
    new Date('2025-04-09T16:00:00Z'),
    new Date('2025-04-09T18:00:00Z'),
    true,
    [UserTypes.SALES, UserTypes.CUSTOMER, UserTypes.SUPER_USER],
    SystemDownTypes.MAINTENANCE
  )*/
]
