import { Language } from 'store/app/types.ts'
import { UserTypes } from 'store/auth/types.ts'

export type KnownDowntimePeriod = {
  start: Date
  end: Date
  customMessage?: {
    [key in Language]: string
  }
  userTypes: UserTypes[]
}

export type CheckIsDown = {
  isDown: boolean
  isApproaching: boolean
  message: string
  type: SystemDownTypes
}

export enum SystemDownTypes {
  MAINTENANCE = 'maintenance',
  UNKNOWN = 'unknown'
}
